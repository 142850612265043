import React from 'react';
import { withPrefix } from 'gatsby';
import classnames from 'classnames';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import styles from './index.module.scss';

export default ({ allergens, appStyles, wrapperClassNames }) =>
  !isEmpty(allergens) && (
    <div className={wrapperClassNames}>
      {map(allergens, allergen => (
        <img src={withPrefix(allergen.path)} className={classnames(styles.AllergenIcon, appStyles.rtl && styles.RTL)} />
      ))}
    </div>
  );
