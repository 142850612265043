import React from "react";
import _ from "lodash";
import AllergensDecorator from "../allergens-decorator";
import styles from "./index.module.scss";

const hasPreference = (title, preference) => {
  return title.indexOf(preference) >= 0;
};

const removePreferencesFromTitle = (title, preferences) => {
  preferences.map((pref) => {
    title = _.replace(title, pref, "");
  });
  return title;
};

export default ({ title, appStyles, allergens }) => (
  <span className={styles.ItemComponentOption}>
    {removePreferencesFromTitle(title, _.map(allergens, "tag"))}
    <AllergensDecorator
      allergens={_.filter(
        allergens,
        (allergen) =>
          !_.isEmpty(allergen.tag) && hasPreference(title, allergen.tag),
      )}
      appStyles={appStyles}
    />
  </span>
);
